

































import { Component, Vue, Watch } from 'vue-property-decorator';

import SupportWidgetSuccessViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-success-view-model';

// Domain
import { EmailEntity } from '@/modules/on-boarding/emails/domain/entities/email-entity';

@Component({ name: 'SupportWidgetSuccess' })
export default class SupportWidgetSuccess extends Vue {
   support_success_wiew_model = Vue.observable(
     new SupportWidgetSuccessViewModel(this),
   );

   @Watch('support_success_wiew_model.emails')
   onEmailsChange(emails: Array<EmailEntity>) {
     this.support_success_wiew_model.setDefaultEmail(emails);
   }

   created() {
     this.onEmailsChange(this.support_success_wiew_model.emails);
   }
}
